import React, { Fragment } from 'react';
//bootstrap
import './bootstrap/bootstrap.css'

// routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// views
import PageSpanish from './Views/Spanish';
import PageEnglish from './Views/English';


// router
import { } from 'react-router-dom';

function App() {
  return (
    <div className="container-fluid">
      <Router>
        <Fragment>
          <Switch>
            <Route exact path='/en' component={PageEnglish} />
            <Route exact path='/es' component={PageSpanish} />
            <Route component={PageEnglish} />
          </Switch>
        </Fragment>
      </Router>
        
  
    </div>
  );
}

export default App;
