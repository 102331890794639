/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Fragment } from 'react';
import './Footer.css'
// iconos
import WpIcon from '../../bootstrap/icons/social/whatsapp.png';
import FaceIcon from '../../bootstrap/icons/social/facebook.png';
import InstIcon from '../../bootstrap/icons/social/instagram.png';
import LinkIcon from '../../bootstrap/icons/social/linckedin.png';


const Footer = () => {
    return (
        <Fragment>
            <div className="jumbotron mt-3">
                <h3 id="contact" name="contact" className="text-center display-5">Contacto</h3>
                <hr/>
                <div className="row text-center">
                    <div className="col-md col-lg text-left">
                        <h4>✉️ Correo Electronico: <a href="mailto:info@iconicsoftware.co">info@iconicsoftware.co</a></h4>
                        <h4>📱 Celular: <a href="https://wa.me/5493815235560">+5493815235560<img src={WpIcon} className="logo-social" alt="logo de whats app"/></a></h4>
                    </div>
                    <div className="col-md col-lg text-left">
                        <h4>👨🏻‍🏫 Cursos y Capacitaciones.</h4>
                        <h4>💻 Reuniones y Workshops.</h4>
                    </div>
                    <div className="col-md col-lg">
                        <h4>Seguinos en redes sociales</h4>
                        <div className="d-flex justify-content-center">
                            <a href="https://www.facebook.com/iconicSoftw/"><img src={FaceIcon} className="logo-social" alt="logo de facebook"/></a>
                            <a href="https://www.instagram.com/iconic_software/"><img src={InstIcon} className="logo-social-ins" alt="logo de instagram"/></a>
                            <a href="https://www.linkedin.com/company/iconic-software/?viewAsMember=true"><img src={LinkIcon} className="logo-social" alt="logo de linckedin"/></a>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer bg-primary text-white">
                Copy Right© 2020. Iconic.Software. All Rights Reserved.
            </div>
        </Fragment>
      );
}
 
export default Footer;
