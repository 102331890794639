import React from 'react';
import './Home.css'
// icons
import HomeIcons from '../../bootstrap/icons/png/022-global network.png';

// <div>Iconos diseñados por <a href="https://www.freepik.com/?__hstc=267336593.ea194d4ed38e1172ebc3cf5e0e2c8385.1562910191976.1562946259096.1562955900152.3&__hssc=267336593.10.1562955900152&__hsfp=1922850038" title="Freepik">Freepik</a> from <a href="https://www.flaticon.es/"             title="Flaticon">www.flaticon.com</a> con licencia <a href="http://creativecommons.org/licenses/by/3.0/"  title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
// <a href="https://www.freepik.es/fotos-vectores-gratis/logo">Vector de Logo creado por saragnzalez - www.freepik.es</a>

const HomeEN = () => {
    return (
        <div className=" mt-3">
            <div className="row">
                <div className="col-md col-lg">
                        <div className="text-center">
                            <img src={HomeIcons} alt="icono web" className="img"/>
                        </div>
                </div>
                <div className="col-md col-lg mt-4">
                    <h1 id="home" className="display-4">  APPS<br/>
                                            SOFTWARE <br/>
                                            & TEAMS
                    </h1>
                    <p className="lead">
                    We are a Freelancers development team. Our job is to provide our clients with the necessary solutions to enhance their business, providing them with the tools and correct guidance to achieve their objectives.
                    </p>
                    <hr className="my-4"/>
                    <p>Your achievements are ours! <span role="img" aria-label="cup">🏆🥇</span></p>
                </div>
            </div>
            <hr/>
        </div>
      );
}
 
export default HomeEN;