/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
// iconos
import DesktopIcon from '../../bootstrap/icons/png/018-ecommerce.png';
import MobileIcon from '../../bootstrap/icons/png/023-online payment.png';
import WebpIcon from '../../bootstrap/icons/png/033-www.png';

const Servicios = () => {
    return (  
        <div className="mt-5">
            <h2 id="services" className="text-center display-4 mb-4">👩🏻‍💻 Nuestros Servicios 🧑🏻‍💻</h2>
            <div className="row">
                <div className="col-lg border border-secondary rounded text-center m-2 p-4" style={{opacity: 6.0}}>
                    <h2>WEB</h2>
                    <hr/>
                    <h4>Paginas y aplicaciones segun la necesidad de tu negocio, brindando una experiencia
                        unica a tus usuarios
                    </h4>
                    <img src={WebpIcon} alt="icono web" className="img"/>
                </div>
                    
                      
                <div className="col-lg border border-secondary  rounded text-center m-2 p-4">
                    <h2>MOBILE</h2>
                    <hr/>
                    <h4>Aplicaciones para las plataformas mas importantes como IOS y Android
                    </h4>
                    <img src={MobileIcon} alt="icono web" className="img"/>
                    
                </div>

                <div className="col-lg border border-secondary  rounded text-center m-2 p-4">
                    <h2>DESKTOP</h2>
                    <hr/>
                    <h4>Programas utiles para tu negocio, desde un ordenador.
                    </h4>
                    <img src={DesktopIcon} alt="icono web" className="img"/>
                    
                </div>
            </div>
        </div>
    );
}
 
export default Servicios;