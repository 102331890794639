import React, {Fragment} from 'react';

// components
import FooterEN from '../../components/Footer/FooterEN';
import HeaderEN from '../../components/Header/Header-EN';
import HomeEN from '../../components/Home/HomeEN';
import ServiciosEN from '../../components/Servicios/ServiciosEN';

// components


class PageEnglish extends React.Component {

    render(){
        return(
            <Fragment>
                <HeaderEN/>
                <HomeEN/>
                <ServiciosEN/>
                <FooterEN/>
            </Fragment>
        )
        
    }

}

export default PageEnglish;