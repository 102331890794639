import React from 'react';

// router
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <h1 className="display-5 mr-2"><b>{`</ic`}<span className="text-info">o</span>{`nic>`}</b></h1>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="#home">Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#services">Servicios</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#contact">Contacto</a>
                    </li>
                </ul>

                <form class="form-inline my-2 my-lg-0">
                    <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="btn btn-outline-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Lenguaje
                    </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <Link className="nav-link" to="/es">Español</Link>
                            <Link className="nav-link" to="/en">Ingles</Link>
                        </div>
                    </div>
                </form>

            </div>



        </nav>
    );
}

export default Header;