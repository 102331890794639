/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
// iconos
import DesktopIcon from '../../bootstrap/icons/png/018-ecommerce.png';
import MobileIcon from '../../bootstrap/icons/png/023-online payment.png';
import WebpIcon from '../../bootstrap/icons/png/033-www.png';

const ServiciosEN = () => {
    return (  
        <div className="mt-5">
            <h2 id="services" className="text-center display-4 mb-4">👩🏻‍💻 Our services 🧑🏻‍💻</h2>
            <div className="row">
                <div className="col-lg border border-secondary rounded text-center m-2 p-4" style={{opacity: 6.0}}>
                    <h2>WEB</h2>
                    <hr/>
                    <h4>
                        Pages and applications according to the need of your business, providing a unique experience to your users
                    </h4>
                    <img src={WebpIcon} alt="icono web" className="img"/>
                </div>
                    
                <div className="col-lg border border-secondary  rounded text-center m-2 p-4">
                    <h2>MOBILE</h2>
                    <hr/>
                    <h4>
                        Applications for the most important platforms such as IOS and Android
                    </h4>
                    <img src={MobileIcon} alt="icono web" className="img"/>
                    
                </div>

                <div className="col-lg border border-secondary  rounded text-center m-2 p-4">
                    <h2>DESKTOP</h2>
                    <hr/>
                    <h4>
                        Useful programs for your business, from a computer.
                    </h4>
                    <img src={DesktopIcon} alt="icono web" className="img"/>
                    
                </div>
            </div>
        </div>
    );
}
 
export default ServiciosEN;