import React, {Fragment} from 'react';

// components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Home from '../../components/Home/Home';
import Servicios from '../../components/Servicios/Servicios';

// components


class PageEnglish extends React.Component {

    render(){
        return(
            <Fragment>
                <Header/>
                <Home/>
                <Servicios/>
                <Footer/>
            </Fragment>
        )
        
    }

}

export default PageEnglish;